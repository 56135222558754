@charset "UTF-8";

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Dashboard {
    width: 75%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Dashboard {
    width: 50%;
  }
}
